import React from 'react';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import { P } from '../components/common/P.style';
import { Img } from '../components/common/Img.style';
import ButtonList from '../components/ButtonList';
import ReserveButton from '../components/ReserveButton';
import { H3, H4, H5 } from '../components/common/H.style';
import Anchor from '../components/Anchor';

import end1md from '../assets/img/end-1-lg.jpg';

const Reserve = ({
  link = 'https://reserva.be/hirahatacovidtest/reserve?mode=service_staff&search_evt_no=61eJwzsTQ2MTYEAARaATk&ctg_no=47eJwzNDYxMgQAAvcA_A',
}) => (
  <ReserveButton
    fullWidth
    height={60}
    link={link}
    external="true"
    label="胃カメラ（上部内視鏡検査）WEB予約はこちら"
    buttonColor="#ffae00"
  />
);

const Endoscopy = () => {
  const { size } = useTrackedState();
  return (
    <SubLayout topTitle="内視鏡検査">
      <Img src={end1md} />
      <Reserve />
      <ButtonList
        size={size}
        list={[
          {
            title: '胃カメラについて',
            link: '/endoscopy#about-gastroscope',
          },
          {
            title: '大腸カメラについて',
            link: '/endoscopy#about-colonoscope',
          },
        ]}
      />
      <P>
        当院では、毎回の検査ごとに丁寧に手洗い等をした上で、カイゲン社のCLEAN TOP
        WM-Sという消化器内視鏡専用の洗浄消毒器を使用しております。
        毎朝、強力な電解酸性水を生成してから使用しておりますので、「消毒液の交換時期が過ぎる」といったことは起きえません。
        内視鏡手術に使う針は使い捨てです。
      </P>
      <Anchor id="about-gastroscope">
        <H3>胃カメラについて</H3>
      </Anchor>
      <H4>鼻から診る胃カメラ</H4>
      <P>
        当院では鼻から診る胃カメラを導入しております。
        口からの胃カメラに比べ、咽頭反射（嘔吐反射）が少ないのが特徴。
        研究によれば9割の方が「口からの胃カメラよりも楽」と答えています。
      </P>
      <P>
        検査を担当する医師は全員日本消化器内視鏡学会の専門医。
        研修医などが担当することはありません。
        予約枠を午前中で６件までに制限することで、一人当たりの時間をたっぷり用意。
        <br />
        流れ作業的に行うのではなく、丁寧に検査、診察を行います。
      </P>
      <H4>当院で使用している胃カメラ</H4>
      <P>
        当院の胃カメラはフジフイルム社製のEG-L580NWを使用しております。
        経口の胃カメラと比べても遜色のない画質で検査を行っており、見逃しのリスクを低減しています。
      </P>
      <H4>料金</H4>
      <P>胃カメラは保険が使えます。 費用は３割負担の場合で3,000円～5,000円程度となります。</P>
      <P>
        ※{` `}
        ポリープや、胃がんを否定しなければならない部位の病理検査をした場合、追加費用がかかります。
      </P>
      <H4>授乳中の方へ</H4>
      <P>
        当院では通常の麻酔はキシロカインを使用します。
        授乳中でもキシロカインを使用した胃カメラを行うことは可能ですが、鎮静剤を使用して眠って行う検査はできませんのでご注意ください。
      </P>
      <P>
        国立成育医療研究センターのサイトでも、キシロカインは授乳中に安全に使える薬としてリストアップされておりますのでご安心ください。
      </P>
      <H4>検査前のお食事・飲み物の注意</H4>
      <H5>午前中に検査を受けられる方</H5>
      <P>
        <span
          css={`
            color: #e83d00;
          `}
        >
          前日の夕食は夜９時までに済ませてください。
        </span>
      </P>
      <P>
        なお、その際、食事はできるだけ白米、うどん、魚など、消化の良いものだけにし、野菜や海藻類は避けるようにして下さい。
      </P>
      <H5>午後に検査を受けられる方</H5>
      <P>
        朝食はウイダーインゼリーなどを摂るようにしてください。
        形のあるものを食べると、胃の中に残ってしまい、正確な診断ができなくなってしまうことがあります。
      </P>
      <Anchor id="about-colonoscope">
        <H3>大腸カメラについて</H3>
      </Anchor>
      <H4>痛みの少ない大腸カメラ</H4>
      <P>
        当院の大腸カメラは拡大機能を搭載しており、ポリープや粘膜を詳細に観察可能です。
        切除が必要か、切除が可能かを判断し、必要であればその場で大腸ポリープを切除します。
      </P>
      <P>
        空気の100～200倍速く吸収される炭酸ガスを使用して検査を施行するため、検査後の「お腹が張って苦しい」といった症状はかなり軽減されています。
      </P>
      <P>
        また、院長は大学で大腸カメラ挿入時疼痛の研究の中心メンバーの一人として活動。痛みのない、または痛みの少ない大腸カメラを施行しています。
      </P>
      <P>
        現在、鎮痛剤・鎮静剤を一切使わない状態でも無痛挿入率は６割を越えております。
        <br />
        （痛みがある場合のみ、鎮痛剤を使用します。）
        <br />
        下剤の処方等が必要ですので、ご希望の方は一度ご受診ください。
      </P>
      <P>
        なお、同研究の成果は世界で最も権威のある消化器関連学会の大会、2008年の米国消化器病週間で口演発表されました。
        抄録はGastrointestinal Endoscopy誌にも掲載されています。
      </P>
      <P>
        Evaluation of Patient Pain During Colonoscopy for Types of Looping Between Hood Attached
        Group and Non-Hood Attached Group: An Analysis Using Magnetic Endoscope Imaging (MEI)
        Koichiro Sato, Koichi Hirahata, Sumio Fujinuma, Tadayoshi Kakemura, Iruru Maetani
        Gastrointestinal Endoscopy April 2008 (Vol. 67, Issue 5, Page AB77)
      </P>
      <H4>大腸カメラ前の処置について</H4>
      <H5>お食事について</H5>
      <P>
        前日から野菜や海藻類、コンニャク、肉類、キノコなど、消化の悪いものは一日食べないようにし、食事はできるだけ白米、うどん、魚、ジャガイモ（皮以外）、豆腐、乳製品、卵、食パン、バナナ、リンゴ（皮以外）など、消化の良いものだけにしてください。
        <br />
        <span
          css={`
            color: #e83d00;
          `}
        >
          前日の夕食は夜９時までに済ませてください。
        </span>
      </P>
      <H5>下剤について</H5>
      <P>
        10mlの下剤（ピコスルファートナトリウム）を前日の眠前に１本すべて飲んでください。
        当日は下剤（マグコロールP）のバッグに水を1.8リットル入れて溶かし、朝6時から2時間かけてゆっくり飲んでください。
        （目安：12分毎に180ml。多少の前後は問題ありませんが、できるだけ２時間以内に飲むようにしてください）
        下剤は自宅で飲んできていただきます。
        また、強い腹痛が出てきた場合、すぐに飲むのをやめ、クリニックまでご連絡ください。
      </P>
    </SubLayout>
  );
};

export default Endoscopy;
